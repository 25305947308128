import { FC } from "react";
import LeftComponent from "../StepTwo/LeftComponent";
import RightComponent from "../StepTwo/RightComponent";
import { FlexBox } from "react-styled-flex";
import { IFilteredData } from "../../Interface/IFilteredData";

interface IProps {
  data: IFilteredData;
}

const StepTwo: FC<IProps> = (props) => {
  const { data } = props;
  return (
    <FlexBox
      center
      justifyContent="center"
      className="contentLayout"
      width="100%"
    >
      <LeftComponent data={data} />
      <RightComponent data={data} />
    </FlexBox>
  );
};
export default StepTwo;
