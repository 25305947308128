import React, { createContext, useState } from "react";

export const MainContext = createContext<any>(null);

export interface IBasicData {
  klika: string;
  klikaName: string;
  klikaUrl: string;
  dvere: number;
  krok: number;
  podlaha: number;
  pozadi: string;
  kategorie: string;
  surface: string;
  w: number;
  filteredData: Array<any>;
  otherSettings: IOtherSettiongs;
}

export interface IOtherSettiongs {
  hideHeader: boolean;
}

export const MainProvider = (props: any) => {
  const [basicData, setBasicData] = useState<IBasicData>({
    klika: "C02511B10",
    klikaName: "",
    klikaUrl: "",
    dvere: 1,
    podlaha: 1,
    pozadi: "#E9E2D8",
    kategorie: "ONS",
    surface: "OSN",
    w: 1,
    krok: 1,
    filteredData: [],
    otherSettings: { hideHeader: false },
  });

  return (
    <MainContext.Provider value={[basicData, setBasicData]}>
      {props.children}
    </MainContext.Provider>
  );
};
