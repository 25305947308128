import { FC, useContext, useEffect, useState } from "react";
import { FlexBox } from "react-styled-flex";
import VariantyComponent from "./VariantyComponent";
import { IBasicData, MainContext } from "../layout/MainProvider";

interface IProps {
  klika: any;
}

const KlikaComponent: FC<IProps> = (props) => {
  const { klika } = props;
  const [basicData, setBasicData] = useContext(MainContext);
  const [vIndex, setVIndex] = useState(0);
  if (!klika?.data) {
    return <></>;
  }

  const onSelectProduct = (product: any) => {
    const res = klika.data.findIndex(
      (item: any) => item.ITEM_ID[0] === product.ITEM_ID[0]
    );
    setVIndex(res);
    setBasicData((prevState: IBasicData) => ({
      ...prevState,
      klika: product.ITEM_ID[0],
      surface: product.SURFACE[0],
    }));
  };
  useEffect(() => {
    setVIndex(0);
  }, [klika]);

  return (
    <>
      {klika.data[0].SURFACE && (
        <FlexBox column className="klikaItem">
          <FlexBox className="klikaTitle">{klika.productName}</FlexBox>
          <FlexBox className="klikaSurface" wrap={false}>
            <strong>
              Zvolený
              <br />
              povrch:
            </strong>{" "}
            {klika.data[vIndex]?.SURFACE[0]}
          </FlexBox>
          <FlexBox className="klikaPrice">
            <strong>Cena:</strong> {klika?.data[vIndex]?.PRICE_VAT[0]} ,-
          </FlexBox>
          <div className="klikaImage">
            <img
              src={`./assets/kliky/${klika?.data[vIndex]?.ITEM_ID[0]}.png`}
              className="img-fluid"
            />
          </div>
          <FlexBox className="klikaSurfaces">
            <VariantyComponent
              varianty={klika.data}
              onSelectProduct={onSelectProduct}
              vIndex={vIndex}
            />
          </FlexBox>
        </FlexBox>
      )}
    </>
  );
};
export default KlikaComponent;
