import { FC, useContext } from "react";
import { FlexBox } from "react-styled-flex";
import door1 from "../../assets/dvere/1.png";
import floor1 from "../../assets/podlahy/1.png";
import floor2 from "../../assets/podlahy/2.png";
import floor3 from "../../assets/podlahy/3.png";
import floor4 from "../../assets/podlahy/4.png";
import floor5 from "../../assets/podlahy/5.png";
import floor6 from "../../assets/podlahy/6.png";
import floor7 from "../../assets/podlahy/7.png";
import floor8 from "../../assets/podlahy/8.png";
import { IBasicData, MainContext } from "../layout/MainProvider";
import door2 from "../../assets/dvere/2.png";
import door3 from "../../assets/dvere/3.png";
import door4 from "../../assets/dvere/4.png";
import door5 from "../../assets/dvere/5.png";
import door6 from "../../assets/dvere/6.png";
import StyledArrowNextButton from "../Styled/StyledArrowNextButton";
import { IFilteredData } from "../../Interface/IFilteredData";
import arrowImg from "../../assets/images/sipka.png";
import Magnifier from "react-magnifier";
import KoupitKlikuComponent from "./KoupitKlikuComponent";

interface IProps {
  data: IFilteredData;
}

const LeftComponent: FC<IProps> = (props) => {
  const { data } = props;
  const [basicData, setBasicData] = useContext(MainContext);

  const onChangeDoor = (door: number) => {
    switch (door) {
      case 1:
        return door1;
      case 2:
        return door2;
      case 3:
        return door3;
      case 4:
        return door4;
      case 5:
        return door5;
      case 6:
        return door6;
    }
    return door1;
  };

  const showFloor = (floor: number) => {
    switch (floor) {
      case 1:
        return floor1;
      case 2:
        return floor2;
      case 3:
        return floor3;
      case 4:
        return floor4;
      case 5:
        return floor5;
      case 6:
        return floor6;
      case 7:
        return floor7;
      case 8:
        return floor8;
    }
  };

  const beforeStep = () => {
    setBasicData((prevState: IBasicData) => ({
      ...prevState,
      krok: 1,
    }));
  };

  return (
    <FlexBox
      className="leftComponent"
      style={{ background: basicData.pozadi }}
      column
    >
      <FlexBox className="buttonZpet" alignItems="center">
        zpět
        <StyledArrowNextButton onClick={beforeStep}>
          k výběru interiéru
        </StyledArrowNextButton>
      </FlexBox>
      <FlexBox className="volbaKliky">
        <KoupitKlikuComponent basicData={basicData} />
      </FlexBox>

      <FlexBox className="door">
        <FlexBox className="arrowZoom">
          Zoom
          <img src={arrowImg} />{" "}
        </FlexBox>

        <FlexBox className="klikaSelected">
          <Magnifier
            src={`./assets/kliky/${basicData.klika}.png`}
            className={`imgKlikaDvere ${basicData.klika}`}
            zoomFactor={5}
            mgHeight={250}
            mgWidth={250}
          />
        </FlexBox>
        <img src={onChangeDoor(basicData.dvere)} className="doorImage" />
      </FlexBox>
      <FlexBox className="floor">
        <img
          src={showFloor(basicData.podlaha)}
          className={`floorImage floor-${basicData.podlaha}`}
        />
      </FlexBox>
    </FlexBox>
  );
};
export default LeftComponent;
