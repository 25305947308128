import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { FlexBox, FlexItem } from "react-styled-flex";
import CategoryStyled from "../Styled/CategoryStyled";
import { IBasicData, MainContext } from "../layout/MainProvider";

interface IProps {
  data: any;
}

const CategoryComponent: FC<IProps> = (props) => {
  const { data } = props;
  const [basicData, setBasicData] = useContext(MainContext);
  const [aCategory, setACategory] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("");

  const onSelectCategory = (cat: string) => {
    setSelectedCategory(cat);
    setBasicData((prevState: IBasicData) => ({
      ...prevState,
      surface: cat,
      kategorie: cat,
    }));
  };

  const onSetCatName = (item: any) => {
    if (item.surfaces[0] && item.surfaces[1] && item.surfaces[2]) {
      return `${item.surfaces[0]} ${item.surfaces[1]} ${item.surfaces[2]}`;
    }
    if (item.surfaces[0] && item.surfaces[1] && !item.surfaces[2]) {
      return `${item.surfaces[0]} ${item.surfaces[1]}`;
    }
    if (item.surfaces[0] && !item.surfaces[1] && !item.surfaces[2]) {
      return `${item.surfaces[0]}`;
    }
  };

  const onCategoryLoad = async () => {
    const aCat = aCategory;

    const promises = data.map((item: any, index: number) => {
      const catname = onSetCatName(item);

      const find = aCategory.find((cat) => cat?.name === catname);
      if (!find) {
        aCat.push({ name: catname, surface: item.surface });
        setACategory(aCat);
      }
    });
    await Promise.all(promises).then(() => {
      onSelectedCategory();
      setLoading(false);
    });
  };

  const onSelectedCategory = useCallback(() => {
    const find = aCategory.find((item) => item.surface === basicData.kategorie);
    if (find) {
      setSelectedCategory(find.name);
    }
  }, [selectedCategory, aCategory]);

  useEffect(() => {
    onCategoryLoad();
  }, [aCategory]);

  return (
    <FlexBox column width="100%" center>
      <FlexBox justifyContent="center" width="100%">
        <FlexBox justifyContent={"center"} width="100%" gap={5} wrap>
          {!loading && (
            <>
              {aCategory.map((item, index: number) => {
                return (
                  <FlexBox key={index}>
                    <FlexItem box>
                      <CategoryStyled
                        onClick={() => onSelectCategory(item.surface)}
                      >
                        {item.name}
                      </CategoryStyled>
                    </FlexItem>
                  </FlexBox>
                );
              })}
            </>
          )}
        </FlexBox>
      </FlexBox>
      <FlexBox>
        <span className="selectedCategory">
          Kateogrie: <span>{selectedCategory}</span>
        </span>
      </FlexBox>
    </FlexBox>
  );
};

export default CategoryComponent;
