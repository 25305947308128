import { FlexBox } from "react-styled-flex";
import LeftComponent from "./LeftComponent";
import RightComponent from "./RightComponent";

const StepOne = () => {
  return (
    <FlexBox
      center
      justifyContent="center"
      className="contentLayout"
      width="100%"
    >
      <LeftComponent />
      <RightComponent />
    </FlexBox>
  );
};
export default StepOne;
