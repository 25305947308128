import { FC, useEffect, useState } from "react";
import StyledArrowButton from "../Styled/StyledArrowButton";
import { IBasicData } from "../layout/MainProvider";

interface IProps {
  basicData: IBasicData;
}

const KoupitKlikuComponent: FC<IProps> = (props) => {
  const { basicData } = props;
  const [klikaUrl, setKlikaUrl] = useState("");
  const [klikaName, setKlikaName] = useState(basicData.klikaName);
  const [klikaPrice, setKlikaPrice] = useState("0");
  const [className, setClassName] = useState("");

  const goToUrl = () => {
    window.location.href = klikaUrl;
  };
  const updateData = () => {
    const timer = setTimeout(() => {
      setClassName("animate__animated animate__fadeIn");
    }, 50);
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    setClassName("");
    const id = basicData.klika;
    basicData.filteredData.map((item) => {
      const find = item.data.find((subitem: any) => subitem.ITEM_ID[0] === id);
      if (find) {
        setKlikaPrice(find.PRICE_VAT[0]);
        setKlikaUrl(find.URL[0]);
        setKlikaName(find.PRODUCTNAME[0]);
        updateData();
      }
    });
  }, [basicData, klikaUrl, klikaPrice]);

  return (
    <a href={klikaUrl} target="_blank" className={`priceBtn ${className}`}>
      koupit
      <StyledArrowButton className="volbaKlikyButton">
        {klikaName} <br />
        {klikaPrice},-
      </StyledArrowButton>
    </a>
  );
};
export default KoupitKlikuComponent;
