import styled from "styled-components";
import { Button } from "antd";

const StyledArrowNextButton = styled(Button)`
  background: #d6001c;
  position: relative;
  height: 50px;
  color: #ffffff;
  font-family: "Patrick Hand", cursive;
  font-size: 24px;
  z-index: 100;
  border: none;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.31);
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.31);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.31);
  margin-left: 25px;
  padding: 0px 12px;
  &:before {
    position: absolute;
    left: -15px;
    top: 9px;
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;

    border-right: 15px solid #d6001c;
  }
`;
export default StyledArrowNextButton;
