import React, {useEffect, useState} from "react";
import Layout from "./Components/layout/Layout";
import "./App.scss";
import {parseString} from "xml2js";
import axios from "axios";
import StepsComponent from "./Components/StepsComponent";
import Preloader from "./Components/Preloader";
import {MainProvider} from "./Components/layout/MainProvider";

interface ILoading {
   kliky: boolean;
   xml: boolean;
   defaultData: any;
   kliky_data: [];
}

function App() {
   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
   // @ts-ignore
   const data = JSON.parse(localStorage.getItem("cobraXmlData"));

   const [loading, setLoading] = useState<ILoading>({
      kliky: false,
      xml: false,
      defaultData: data,
      kliky_data: [],
   });

   const loadingKliky = async () => {
      const path = `data/kliky.json`;

      const d: [] = await axios.get(path).then((res) => {
         return res.data;
      });

      setLoading((prevState) => ({
         ...prevState,
         kliky: true,
         kliky_data: d,
      }));
   };

   const onSetData = (aData: any) => {
      localStorage.setItem("cobraXmlData", JSON.stringify(aData));
      setLoading((prevState) => ({
         ...prevState,
         xml: true,
         defaultData: aData
      }));
   };

   useEffect(() => {
      if (!loading.kliky) {
         loadingKliky();
      }

      if (loading.kliky && !loading.defaultData) {

         axios
            .get(`https://www.matekliku.cz/feeds/ZboziRozsireny`)
            .then(function (res) {
               parseString(res.data, function (err: any, results: any) {
                  onSetData(results.SHOP.SHOPITEM);
               });
            });
      } else {
         setLoading((prevState) => ({
            ...prevState,
            xml: true,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            defaultData: JSON.parse(localStorage.getItem("cobraXmlData")),
         }));
      }
   }, [loading.kliky]);

   if (!loading.kliky || !loading.xml || !loading.defaultData) {
      return <Preloader/>;
   }

   return (
      <MainProvider>
         <Layout>
            <StepsComponent data={loading.defaultData} kliky={loading.kliky_data}/>
         </Layout>
      </MainProvider>
   );
}

export default App;
