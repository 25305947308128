import { FC, useCallback, useContext, useEffect, useState } from "react";
import { IData } from "../Interface/IData";
import { FlexBox } from "react-styled-flex";
import { MainContext } from "./layout/MainProvider";
import StepOne from "./StepOne/StepOne";
import StepTwo from "./StepTwo/StepTwo";
import Preloader from "./Preloader";

interface IProps {
  data: IData;
  kliky: any;
}

const StepsComponent: FC<IProps> = (props) => {
  const { data, kliky } = props;
  const [basicData, setBasicData] = useContext(MainContext);
  const [filteredData, setFilteredData] = useState<any>();
  const aResult: any[] = [];
  const aSurfaces: any[] = [];

  const onSetData = useCallback(
    (aData: []) => {
      localStorage.setItem("cobraXmlFilteredData", JSON.stringify(aData));
      setBasicData((prevState: any) => ({
        ...prevState,
        filteredData: aData,
      }));
    },
    [filteredData]
  );

  const onSetR = (r: any) => {
    let surfaces: any[] = ["Černá"];
    if (!r) {
      localStorage.setItem(r, "r");
    } else {
      const name = r.PRODUCTNAME[0].split(" ");
      if (name[0] === "MODEL") {
        name[0] = `${name[0]} ${name[1]}`;
      }
      if (r.SURFACE) {
        surfaces = r?.SURFACE[0]?.split(" ");
      }

      const find = aResult.find((item) => item.productName === name[0]);
      if (find) {
        const index = aResult.indexOf(find);
        const newData = aResult[index].data;
        newData.push(r);
        aResult[index] = {
          productName: name[0],
          data: newData,
          surfaces,
          surface: surfaces[0],
        };
      } else {
        aResult.push({
          productName: name[0],
          data: [r],
          categoryNames: [r],
          surfaces,
          surface: surfaces[0],
        });
      }
    }
    return aResult;
  };

  const onFilterData = async (data: any) => {
    let aData: [] = [];

    const promises = kliky.map((klika: string) => {
      const r = data.find((item: any) => item.ITEM_ID[0].toString() === klika);

      if (r) {
        const n = onSetR(r);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        aData = n;
      }
    });

    await Promise.all(promises).then(() => {
      onSetData(aData);
    });
  };

  useEffect(() => {
    onFilterData(data);
  }, []);

  if (basicData.filteredData.length === 0) {
    return <Preloader />;
  }

  return (
    <FlexBox width="100%" justifyContent="center" center className="components">
      {basicData.krok === 1 && <StepOne />}
      {basicData.krok === 2 && <StepTwo data={basicData.filteredData} />}
    </FlexBox>
  );
};
export default StepsComponent;
