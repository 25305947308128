import { FC, useContext } from "react";
import { FlexBox } from "react-styled-flex";
import door1 from "../../assets/dvere/1.png";
import floor1 from "../../assets/podlahy/1.png";
import floor2 from "../../assets/podlahy/2.png";
import floor3 from "../../assets/podlahy/3.png";
import floor4 from "../../assets/podlahy/4.png";
import floor5 from "../../assets/podlahy/5.png";
import floor6 from "../../assets/podlahy/6.png";
import floor7 from "../../assets/podlahy/7.png";
import floor8 from "../../assets/podlahy/8.png";
import StyledArrowButton from "../Styled/StyledArrowButton";
import { MainContext } from "../layout/MainProvider";
import door2 from "../../assets/dvere/2.png";
import door3 from "../../assets/dvere/3.png";
import door4 from "../../assets/dvere/4.png";
import door5 from "../../assets/dvere/5.png";
import door6 from "../../assets/dvere/6.png";
import Magnifier from "react-magnifier";

const LeftComponent: FC = () => {
  const [basicData, setBasicData] = useContext(MainContext);

  const onChangeDoor = (door: number) => {
    switch (door) {
      case 1:
        return door1;
      case 2:
        return door2;
      case 3:
        return door3;
      case 4:
        return door4;
      case 5:
        return door5;
      case 6:
        return door6;
    }
    return door1;
  };

  const showFloor = (floor: number) => {
    switch (floor) {
      case 1:
        return floor1;
      case 2:
        return floor2;
      case 3:
        return floor3;
      case 4:
        return floor4;
      case 5:
        return floor5;
      case 6:
        return floor6;
      case 7:
        return floor7;
      case 8:
        return floor8;
    }
  };

  return (
    <FlexBox
      className="leftComponent"
      width="100%"
      style={{ background: basicData.pozadi }}
    >
      <FlexBox className="volbaSteny">
        zvolte<StyledArrowButton>barvu stěny</StyledArrowButton>
      </FlexBox>
      <FlexBox className="volbaPodlahy">
        zvolte<StyledArrowButton>typ podlahy</StyledArrowButton>
      </FlexBox>

      <FlexBox className={`floor floor-${basicData.podlaha}`}>
        <FlexBox className="door">
          <FlexBox className="volbaDveri">
            zvolte<StyledArrowButton>typ dveří</StyledArrowButton>
          </FlexBox>
          <FlexBox className="klikaSelected">
            <Magnifier
              src={`./assets/kliky/${basicData.klika}.png`}
              className={`imgKlikaDvere ${basicData.klika}`}
              zoomFactor={5}
              mgHeight={250}
              mgWidth={250}
            />
          </FlexBox>
          <img src={onChangeDoor(basicData.dvere)} className="doorImage" />
        </FlexBox>
        <img
          src={showFloor(basicData.podlaha)}
          className={`floorImage floor-${basicData.podlaha}`}
        />
      </FlexBox>
    </FlexBox>
  );
};
export default LeftComponent;
