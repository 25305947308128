import { FlexBox } from "react-styled-flex";
import { FC } from "react";

const Layout: FC<any> = (props) => {
  return (
    <FlexBox width="100%" center column>
      {props.children}
    </FlexBox>
  );
};
export default Layout;
