import { FlexBox } from "react-styled-flex";
import door1 from "../assets/dvere/1.png";
import door2 from "../assets/dvere/2.png";
import door3 from "../assets/dvere/3.png";
import door4 from "../assets/dvere/4.png";
import door5 from "../assets/dvere/5.png";
import door6 from "../assets/dvere/6.png";
import { useContext } from "react";
import { MainContext } from "./layout/MainProvider";

const DoorComponent = () => {
  const [basicData, setBasicData] = useContext(MainContext);
  const doors = [1, 2, 3, 4, 5, 6];

  const onUpdateDoor = (door: number) => {
    setBasicData((prevState: any) => ({
      ...prevState,
      dvere: door,
    }));
  };

  const showDoor = (door: number) => {
    switch (door) {
      case 1:
        return door1;
      case 2:
        return door2;
      case 3:
        return door3;
      case 4:
        return door4;
      case 5:
        return door5;
      case 6:
        return door6;
    }
  };
  return (
    <FlexBox gap={8}>
      {doors.map((item, index) => (
        <FlexBox key={index} onClick={() => onUpdateDoor(item)}>
          <img className="selectDoor" src={showDoor(item)} />
        </FlexBox>
      ))}
    </FlexBox>
  );
};
export default DoorComponent;
