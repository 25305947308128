import { FC, useContext } from "react";
import floorSmall1 from "../assets/podlahy/1.jpg";
import floorSmall2 from "../assets/podlahy/2.jpg";
import floorSmall3 from "../assets/podlahy/3.jpg";
import floorSmall4 from "../assets/podlahy/4.jpg";
import floorSmall5 from "../assets/podlahy/5.jpg";
import floorSmall6 from "../assets/podlahy/6.jpg";
import floorSmall7 from "../assets/podlahy/7.jpg";
import floorSmall8 from "../assets/podlahy/8.jpg";
import { MainContext } from "./layout/MainProvider";
import { FlexBox } from "react-styled-flex";

const FloorComponent: FC = () => {
  const [basicData, setBasicData] = useContext(MainContext);
  const floors = [1, 2, 3, 4, 5, 6, 7, 8];
  const onUpdateFloor = (floor: number) => {
    setBasicData((prevState: any) => ({
      ...prevState,
      podlaha: floor,
    }));
  };
  const showFloor = (floor: number) => {
    switch (floor) {
      case 1:
        return floorSmall1;
      case 2:
        return floorSmall2;
      case 3:
        return floorSmall3;
      case 4:
        return floorSmall4;
      case 5:
        return floorSmall5;
      case 6:
        return floorSmall6;
      case 7:
        return floorSmall7;
      case 8:
        return floorSmall8;
    }
  };
  return (
    <FlexBox gap={5} wrap center mt={24}>
      {floors.map((item, index) => (
        <FlexBox key={index} wrap onClick={() => onUpdateFloor(item)}>
          <img className="selectFloor" src={showFloor(item)} />
        </FlexBox>
      ))}
    </FlexBox>
  );
};
export default FloorComponent;
