import { FC, useState } from "react";
import { FlexBox } from "react-styled-flex";

interface IProps {
  varianty: any;
  vIndex: number;
  onSelectProduct: (item: any) => void;
}

const VariantComponent: FC<IProps> = (props) => {
  const { varianty, onSelectProduct, vIndex } = props;
  const aPole: any[] = [];

  const selectProduct = (product: string) => {
    const find = varianty.find((item: any) => item.ITEM_ID[0] === product);
    onSelectProduct(find);
  };

  const setClassName = (item: string) => {
    const surfaces = item?.split(" ");
    switch (surfaces[0]) {
      case "Černá":
        return "black";
      case "TITAN":
        return "titan";
      case "ONS":
        return "ons";
    }
    return surfaces[0];
  };

  return (
    <>
      <FlexBox gap={5} wrap className="varianty">
        {varianty.map((item: any, index: number) => {
          if (item?.SURFACE) {
            const surfaces = item?.SURFACE[0]?.split(" ");
            const find = aPole.find((item: any) => {
              item === surfaces[0];
            });
            if (!find) {
              aPole.push(surfaces[0]);
              return (
                <FlexBox
                  key={`war-${index}`}
                  className={`varianta ${
                    vIndex === index ? "active" : ""
                  } ${setClassName(item?.SURFACE[0])}`}
                  onClick={() => selectProduct(item.ITEM_ID[0])}
                >
                  &nbsp;
                </FlexBox>
              );
            }
          }
        })}
      </FlexBox>
    </>
  );
};
export default VariantComponent;
