import styled from "styled-components";

const CategoryStyled = styled.a`
  background: #000000;
  color: #ffffff;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  width: 100px;
  cursor: pointer;
`;
export default CategoryStyled;
