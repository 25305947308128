import { FlexBox } from "react-styled-flex";
import imgLogo from "../assets/images/logo.png";
import { Spin } from "antd";
import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

const Preloader = () => {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 64, color: "#e30613" }} spin />
  );
  return (
    <FlexBox
      center
      justifyItems="center"
      alignItems="center"
      style={{ height: "100vh", display: "flex", backgroundColor: "#999999" }}
      column
    >
      <FlexBox center>
        <img src={imgLogo} style={{ width: 320 }} />
      </FlexBox>
      <Spin indicator={antIcon} />
    </FlexBox>
  );
};
export default Preloader;
