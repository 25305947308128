import { ChromePicker } from "react-color";
import { FlexBox } from "react-styled-flex";
import { useContext } from "react";
import { MainContext } from "./layout/MainProvider";

const ColorPickerComponent = () => {
  const [basicData, setBasicData] = useContext(MainContext);

  const onSetColor = (color: any) => {
    setBasicData((prevState: any) => ({
      ...prevState,
      pozadi: color.hex,
    }));
  };
  return (
    <FlexBox className="colorPicker">
      <ChromePicker color={basicData.pozadi} onChangeComplete={onSetColor} />
    </FlexBox>
  );
};
export default ColorPickerComponent;
