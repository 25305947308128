import { FC, useContext } from "react";
import { FlexBox } from "react-styled-flex";
import logo from "../../assets/images/logo2.png";
import DoorComponent from "../DoorComponent";
import ColorPickerComponent from "../ColorPickerComponent";
import FloorComponent from "../FloorComponent";
import StyledArrowNextButton from "../Styled/StyledArrowNextButton";
import { IBasicData, MainContext } from "../layout/MainProvider";

const RightComponent: FC = () => {
  const [basicData, setBasicData] = useContext(MainContext);

  const nextStep = () => {
    setBasicData((prevState: IBasicData) => ({ ...prevState, krok: 2 }));
  };

  return (
    <FlexBox className="rightComponent" width="100%" column>
      <FlexBox className="logo" width="100%" center column>
        <img src={logo} alt="cobra" />
      </FlexBox>
      <FlexBox className="description" width="100%" center column>
        <h1>SIMULÁTOR</h1>
        <p>
          Vyzkoušejte si, jak bude vypadat nová klika na Vašich dveřích. Zvolte
          si pohodlně typ dveří, barvu stěny a typ podlahy, které máte ve svém
          interiéru a vyzkoušjte naše kliky přímo u Vás doma. Kliku si budete
          moci lehce přiblížit a po výběru si ji zakoupit v našem eshopu.
        </p>
      </FlexBox>
      <FlexBox
        className="doorsComponent"
        justifyContent="center"
        width="100%"
        center
      >
        <DoorComponent />
      </FlexBox>
      <FlexBox center width="100%">
        <FlexBox className="floorsComponent">
          <FloorComponent />
        </FlexBox>
      </FlexBox>
      <FlexBox center justifyContent="center" width="100%">
        <FlexBox
          className="collorAndButton"
          width="100%"
          justifyContent="space-between"
          alignItems={"end"}
          mt={24}
        >
          <ColorPickerComponent />
          <FlexBox
            className="btn-next"
            justifyContent={"center"}
            alignItems={"center"}
          >
            Další krok
            <StyledArrowNextButton onClick={nextStep}>
              Volba kliky
            </StyledArrowNextButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};
export default RightComponent;
