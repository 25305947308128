import { FC, useCallback, useContext, useEffect, useState } from "react";
import { IShoItem } from "../../Interface/IFilteredData";
import { MainContext } from "../layout/MainProvider";
import { FlexBox } from "react-styled-flex";
import KlikaComponent from "./KlikaComponent";

interface IProps {
  data: any;
}

const KlikyComponent: FC<IProps> = (props) => {
  const { data } = props;
  const [basicData, setBasicData] = useContext(MainContext);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const filterDataOnLoad = useCallback(async () => {
    const filtered = data.filter(
      (item: any) => item.surface === basicData.kategorie
    );

    await Promise.all(filtered).then(() => {
      setLoading(false);
      setProducts(filtered);
    });
  }, [basicData]);

  useEffect(() => {
    filterDataOnLoad();
  }, [basicData]);

  return (
    <FlexBox wrap width="100%" className="klikyVypis">
      {products.map((klika: IShoItem, index: number) => {
        return <KlikaComponent key={index} klika={klika} />;
      })}
    </FlexBox>
  );
};
export default KlikyComponent;
