import { FC } from "react";
import { FlexBox } from "react-styled-flex";
import logo from "../../assets/images/logo2.png";
import CategoryComponent from "./CategoryComponent";
import { IFilteredData } from "../../Interface/IFilteredData";
import KlikyComponent from "./KlikyComponent";

interface IProps {
  data: IFilteredData;
}

const RightComponent: FC<IProps> = (props) => {
  const { data } = props;

  return (
    <FlexBox className="rightComponent step2" column>
      <FlexBox className="logo" width="100%" center column>
        <img src={logo} alt="cobra" />
      </FlexBox>
      <FlexBox
        className="description"
        width="100%"
        justifyContent={"center"}
        center
        column
      >
        <h1>SIMULÁTOR</h1>
        <p>
          Chcete změnit interiér? Vraťte se o krok zpět. Nyní vyberte kategorii
          a vaši kliku. Kliku zobrazíte na dveřích kliknutím na povrch
          (barevnost) kliky pod hlavní klikou. Kliknutím na tlačítko koupit se
          dostanete na náš eshop.
        </p>
      </FlexBox>
      <FlexBox
        className="categoryComponent"
        width="100%"
        center
        justifyContent="center"
      >
        <CategoryComponent data={data} />
      </FlexBox>
      <FlexBox
        className="categoryComponent"
        width="100%"
        justifyContent="space-between"
      >
        <KlikyComponent data={data} />
      </FlexBox>
    </FlexBox>
  );
};
export default RightComponent;
